.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu-item-selected {
  background: #fff !important;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.cactus-classical-serif-regular {
  font-family: "Cactus Classical Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.app-logo {
  font-family: "Cactus Classical Serif", serif;
  font-weight: 400;
  font-style: normal;
}

@media (width < 775px) {
  .app-logo {
    font-size: 0.3em;
  }
}
