h1.subscription-header {
  text-align: center;
}
.tiers {
  display: flex;
  gap: 1%;
  justify-content: center;
}
.tier-col {
  width: 33%;
  max-width: 300px;
  min-height: 500px;
  background: #eee;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.tier-col h2 {
  margin: 0;
  padding: 10px;
  background: #333;
  color: #eee;
  text-align: center;
  width: 100%;
}

.tier-col button {
  border: 0;
  background: green;
  font-weight: bold;
  color: #fff;
  font-size: 2em;
  margin: 0 auto;
  display: block;
  border-radius: 3px;
}

.cta-wrapper {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
