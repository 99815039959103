/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
/* ol,
ul,
li, */
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*  END CSS RESET */

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}
.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
  width: 100%;
}
header {
  background: #333;
  color: #fff;
  padding-top: 30px;
  min-height: 70px;
  border-bottom: #0779e4 3px solid;
}
header a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
}
header ul {
  padding: 0;
  list-style: none;
}
header li {
  display: inline;
  padding: 0 20px 0 20px;
}
.showcase {
  min-height: 400px;
  background: url("https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/galleries/6667757579923d9d8629a390/images/l/666775ad79923d9d8629a3b6.jpeg")
    no-repeat 0;
  text-align: center;
  color: #fff;
  width: 100%;
  background-position: center;
  background-size: cover;
  /* background-size: cover; */
}

@media (width > 800px) {
  .showcase {
    min-height: 400px;
    background: url("https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/galleries/6667757579923d9d8629a390/images/l/666775ad79923d9d8629a3b6.jpeg")
      no-repeat 0 -500px;
    text-align: center;
    color: #fff;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
}
.showcase h1 {
  margin-top: 100px;
  font-size: 55px;
  margin-bottom: 10px;
}
.showcase-2 h1 {
  margin-top: 40px;
  margin-left: 25px;
  font-size: 55px;
  margin-bottom: 10px;
}
.showcase p {
  font-size: 20px;
}

.showcase-2 {
  background: url("https://magiccityfilmmakers.s3.us-east-2.amazonaws.com/galleries/666738c0876086543299c234/images/l/66abda351f8acf3bef7c17d0.jpeg")
    no-repeat 0 -800px;
  color: #fff;
  width: 100%;
}
.main-content {
  padding: 20px;
  margin: 20px 0;
}
.main-content h2 {
  color: #333;
}

.container h2 {
  font-size: 1.5em;
  margin: 25px 0;
}

.marketing-header h1 {
  font-size: 2em;
}
.marketing-container h3 {
  color: #666;
  text-align: center;
  font-size: 1.5em;
}
.main-content p {
  color: #333;
}
footer {
  background: #333;
  color: #777;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

footer .container p {
  padding: 20px;
}

footer .container p:last-child {
  padding-top: 0px;
}

.marketing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.landing-col p {
  color: #999;
  padding: 1.5em;
}
.marketing-copy,
.marketing-header {
  width: 100%;
}

.marketing-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

@media (width > 800px) {
  .marketing-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding: 30px;
  }
}

.marketing-copy {
  display: block;
}

.marketing-copy .container {
  width: 80%;
}
@media (width > 1040px) {
  .marketing-copy {
    max-width: 1300px;
    padding: 9px 25px;
    border-radius: 3px;
    margin-bottom: 25px;
    display: flex;
  }
}
.marketing-nav a {
  text-decoration: none;
  color: #999;
  padding: 10px 25px;
}

.landing-grid {
  margin-top: 25px;
}

@media (width > 800px) {
  .landing-grid {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
  }
  .landing-col {
    width: 20%;
    padding: 20px;
  }
}

/* nav animations */

@-webkit-keyframes force-field {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 100%;
    height: 3px;
  }
}
@keyframes force-field {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 100%;
    height: 3px;
  }
}
.nav-animation {
  position: relative;
  transition: opacity 0.3s;
}
.nav-animation:hover {
  opacity: 0.5;
}
.nav-animation:before {
  content: "";
  background-color: #003;
  opacity: 0;
  position: absolute;
  width: 1px;
  bottom: -4px;
  left: 0;
}
.nav-animation:hover:before {
  -webkit-animation: force-field 1s;
  animation: force-field 1s;
}
