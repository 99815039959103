.login-form-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-input {
  padding: 15px;
  background-color: #e8f0fe;
}

.login-form {
  width: 100%;
  padding: 25px;
  max-width: 420px;
}
/* @media (width > 770px) {
  .login-form {
    width: 100%;
    max-width: 420px;
  }
} */
/* 
  .login-input input {
    padding: 15px;
  } */
