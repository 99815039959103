.web-designer-panel {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.web-designer-panel-nav {
  flex: 1 1 300px;
}

.web-designer-panel-layout {
  flex: 1 1 100%;
  background: #eee;
  padding: 100px;
  border-radius: 3px;
}

/* .actions-panel {
  opacity: 0.3;
} */

.actions-panel a {
  color: #444;
}

.actions-panel:hover {
  opacity: 1;
}
