body {
  margin: 0;
  font-family: sans-serif;
}
/*
.site-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-direction: row; 
}
 
h1 {
  margin: 50px;
} 
*/
.title {
  font-size: 1.5em;
  text-align: center;
  padding: 15px;
}

.galleryActions {
  text-align: center;
  padding-bottom: 15px;
}

.grid {
  /* display: flex;
  gap: 6px;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  transition: all 1s; */

  display: flex;
  gap: 6px;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  transition: all 1s;
}

.grid-column {
  width: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  gap: 7px;
}
/* .grid-item {
  overflow: hidden;
  flex: 1 1 24%;
  height: 273px;
} */

.grid-item a {
  width: 100%;
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.grid-item img:hover {
  cursor: pointer;
}
