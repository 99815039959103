.grid {
  text-align: center;
}
.image {
  background-repeat: no-repeat;
  background-color: #000;
  background-size: contain;
  background-position: center;
  display: inline-block;
  font-size: 16px;
  height: 200px;
  margin: 10px;
  position: relative;
  width: 300px;
  overflow: hidden;
}
.image:hover .image-options {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.image-options {
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  opacity: 0;
  position: absolute;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.3s, opacity 0.9s ease;
}
.image-options button {
  background-color: transparent;
  border: 0;
  color: #888;
  float: left;
  margin: 0;
  padding: 10px;
  width: 50%;
  cursor: pointer;
}
.image-options button:hover {
  background-color: #222;
}
