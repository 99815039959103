.list-view-tool-bar {
  display: flex;
  padding: 20px 40px 40px;
  align-items: center;
  gap: 20px;
}
.gallery-list-item {
  position: relative;
  background-color: #fff;
}
.gallery-list-item-img-container {
  display: flex;
  justify-content: space-between;
}

.gallery-list-item-img-container img {
  flex: 1 1 200;
  width: 20%;
}

.gallery-list-item-cover {
  background-color: rgb(255 255 255 / 56%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  bottom: 0;
  align-items: center;
  color: #494949;
  justify-content: flex-start;
  transition: all 0.5s;
  /* padding-left: 25px; */
}

.gallery-list-item-cover h2 {
  font-size: 2em;
  background: #ffffffb3;
  padding: 10px 25px;
}

.gallery-list-item-cover:hover {
  background-color: #ffffff41;
  color: #000000;
}

h2 {
  color: #555;
}
