@media (width > 650px) {
  .gallery-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
    flex-direction: row;
  }
}

.gallery-add-new-item {
  margin-bottom: 25px;
}

.gallery-list-item {
  border: 1px solid #ccc;
  margin-bottom: 25px;
}

@media (width > 650px) {
  .gallery-list-item {
    width: 22%;
  }
}

.gallery-list-item:hover .gallery-list-item-img-container {
  opacity: 1;
}

.gallery-list-item-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}
.gallery-list-item-img-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}

.gallery-list-item-menu a,
.gallery-item-actions {
  transition: all 0.4;
}

.gallery-item-actions:hover {
  color: #333;
}
